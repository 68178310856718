/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

class CustomCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_checked: props.isChecked ? true : false
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState({ is_checked: !this.state.is_checked });
    }
    render() {
        const { isChecked, number, label, inline, className, ...rest } = this.props;
        let classes = inline !== undefined ? "checkbox checkbox-inline" : "checkbox";
        if (className) {
            classes += ` ${className}`;
        }
        return (
            <div className={classes}>
                <input
                    id={number}
                    type="checkbox"
                    onChange={this.handleClick}
                    checked={this.state.is_checked}
                    {...rest}
                />
                <label htmlFor={number}>{label}</label>
            </div>
        );
    }
}

export default CustomCheckbox;
