import ExpertForm from "./views/Expert/Form/Form.jsx"

var routes = [
    {
        layout: "/",
        path: "/",
        name: "Nowe zamówienie",
        hideInMenu: true,
        component: ExpertForm
    }
];
export default routes;
