/* global _api */

import React, { Component } from "react";
import _ from "lodash";
import Card from "../../../components/Card/Card.jsx";
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import Radio from "../../../components/CustomRadio/CustomRadio.jsx";
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import 'moment/locale/pl';
import {
    Grid,
    Row,
    Table,
    Col,
    FormGroup,
    FormControl,
    Modal,
    ControlLabel,
    Panel,
    Clearfix
} from "react-bootstrap";
import {TypeOfService} from "../../../variables/TypeOfService.jsx"

import bgImage from "../../../assets/img/expert/cover-form-suus.jpg";
import logo from "../../../assets/img/expert/logo-expert-white-background.png";

moment.locale('pl');

class ExpertForm extends Component {

    constructor(props) {
        super(props);

        this.storeOptions = [
            {
                "value": "f46cf4b1-1108-4829-9b54-17829fee2198",
                "type": "agata-form-wloclawek",
                "label": "Salon Agata Włocławek,\nul. Kapitulna 39, 87-800 Włocławek",
                "pokEmailAddress": "pok.wloclawek@suus.com"
            },
            {
                "value": "d5888432-349d-45ce-bc41-ac61884c5660",
                "type": "agata-form-torun",
                "label": "Salon Agata Toruń,\nul. Olsztyńska 15, 87-100 Toruń",
                "pokEmailAddress": "pok.torun@suus.com"
            },
            {
                "value": "0c67b733-68e4-42a2-aa8f-a01e8ab8542d",
                "type": "agata-form-gdansk",
                "label": "Salon Agata Gdańsk,\nul. Przywidzka 10, 80-180 Gdańsk",
                "pokEmailAddress": "pok.gdansk@suus.com"
            },
            {
                "value": "154d63ce-0d48-483f-aa2e-c81115f3173c",
                "type": "agata-form-rumia",
                "label": "Salon Agata Rumia,\nul. Grunwaldzka 112, 84-230 Rumia",
                "pokEmailAddress": "pok.rumia@suus.com"
            },
            {
                "value": "b500c10d-83e8-422a-af63-123944de5894",
                "type": "agata-form-szczecin",
                "label": "Salon Agata Szczecin,\nul. Wiosenna 30, 70-807 Szczecin",
                "pokEmailAddress": "pok.szczecin@suus.com"
            },
            {
                "value": "5fda2743-eda1-4f82-a3c5-117ad0cd5a58",
                "type": "agata-form-bydgoszcz",
                "label": "Salon Agata Bydgoszcz,\nul. Jasiniecka 4, 85-796 Bydgoszcz",
                "pokEmailAddress": "pok.bydgoszcz@suus.com"
            },
            {
                "value": "8e3d9615-34d7-4d6e-88fd-999fb337c290",
                "type": "agata-form-slupsk",
                "label": "Salon Agata Słupsk,\nul. Szczecińska 3D, 76-251 Kobylnica",
                "pokEmailAddress": "pok.slupsk@suus.com",
            },
            {
                "value": "46cdb758-a5b3-40fe-8909-fbcc5e89b0f3",
                "type": "agata-form-poznan",
                "label": "Salon Agata Poznań,\nul. Haliny Konopackiej 18, 60-205 Poznań",
                "pokEmailAddress": "pok.poznan@suus.com",
            },
            {
                "value": "6827d790-0140-4bbc-9087-a2b5e9b0f16f",
                "type": "agata-form-gorzow",
                "label": "Salon Agata Gorzów Wielkopolski,\nul. Myśliborska 35, 66-400 Gorzów Wielkopolski",
                "pokEmailAddress": "pok.gorzow@suus.com",
            },
            {
                "value": "4034a7f5-aa74-4945-aaf7-f3a7276e6f08",
                "type": "agata-form-koszalin",
                "label": "Salon Agata Koszalin,\nul. Koszalińska 5, 75-900 Koszalin",
                "pokEmailAddress": "pok.koszalin@suus.com",
            },
        ];

        this.elementPrototype = {
            "name": "",
            "weight": "",
            "remove": false
        };

        this.state = {
            elements: [this.elementPrototype],
            store: this.storeOptions[0],
            currentStep: 1,
            days: [{}, {}, {}, {}, {}],
            companyData: null,
            selectedCustomDate: moment(),
            showInfoModal: false,
            showTermsModal: false,
            showSummaryModal: false,
            getInvoice: false,
            streetAddress: "",
            streetNumber: "",
            apartmentNumber: "",
            apartmentFloor: "",
            cityName: "",
            postalCode: "",
            firstName: "",
            lastName: "",
            emailAddress: "",
            mobilePhone: "",
            invoiceCompanyName: "",
            invoiceApartmentNumber: "",
            invoiceCityName: "",
            invoicePostalCode: "",
            invoiceStreetAddress: "",
            invoiceStreetNumber: "",
            invoiceTaxNumber: "",
            comment: "",
            calculatedPriceClient: 0,
            calculatedPriceFleet: 0,
            calculatedPriceReal: 0,
            calculatedPoints: 0,
            dayIdent: "",
            dayPart: "",
            payer: "",
            showSaved: false,
            consent1: false,
            consent2: false,
            typeOfService: TypeOfService.get('BASIC'),
            service: ""
        };
    }

    getCalculatedPriceClient() {
        return Math.round(parseFloat(this.state.calculatedPriceClient)).toFixed(2);
    }

    componentDidMount() {
        this.reFetchSlots();
    }

    reFetchSlots() {
        let first = (moment().hour() > 17) ? 2 : 1;
        let days = [];

        for (let i = first; i < 14; i++) {
            let newDay = moment().add(i, 'd');
            if (newDay.day() === 0) {
                continue;
            }
            let ident = newDay.format('DD.MM.YYYY') + ", " + newDay.format("dddd");
            days.push({
                name: ident,
                value: newDay.format('YYYY-MM-DD')
            });
        }

        this.setState({ days: days, selectedCustomDate: moment(days[3].value) });

        let self = this;
        _api.request(`/external/company-configuration/${self.state.store.value}`, {},
            (data) => {
                self.setState({ companyData: data });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );

    }

    getElementsWeight() {
        let totalWeight = 0;
        for (let i = 0; i < this.state.elements.length; i++) {
            let el = this.state.elements[i];
            if (el.remove) {
                continue;
            }
            totalWeight += el.weight ? parseFloat(el.weight) : 0;
        }
        return totalWeight;
    }

    reCalculate() {

        if (!this.state.postalCode) {
            return;
        }

        let weightLeft = this.getElementsWeight();
        let clientPrice = 0;
        let fleetPrice = 0;
        let realPrice = 0;
        let points = 0;
        let selectedIndex = -1;
        let selectedZone = null;

        for (let i = 0; i < this.state.companyData.zone.length; i++) {
            let currentZone = this.state.companyData.zone[i];
            let maxWeight = parseFloat(currentZone.weight);

            if (weightLeft <= maxWeight) {
                if (_.includes(currentZone.postalCode, this.state.postalCode)) {
                    selectedIndex = i;
                }
            }

            if (selectedIndex !== -1) {
                break;
            }
        }

        if (selectedIndex === -1) {
            this.props.showNoty('tr', 'error', 'Podany adres wykracza poza strefy dostaw');
            this.setState(
                {
                    calculatedZone: null,
                    calculatedZoneData: null,
                    calculatedPriceClient: clientPrice,
                    calculatedPriceFleet: fleetPrice,
                    calculatedPriceReal: realPrice,
                    calculatedPoints: points
                }
            );
            return;
        }

        selectedZone = this.state.companyData.zone[selectedIndex];
        points = parseInt(selectedZone.points, 10);

        clientPrice += parseFloat(selectedZone.basePriceClient);
        fleetPrice += parseFloat(selectedZone.basePriceFleet);
        realPrice += parseFloat(selectedZone.basePriceReal);

        if (this.state.typeOfService !== TypeOfService.get('BASIC')) {
            let selectedService = null;
            const sortedServices = this.state.companyData.service.filter(
                (service) => {
                    return service.name.toLowerCase().includes(this.state.typeOfService.toLowerCase())
                }
            ).sort((a, b) => {
                return a.weight - b.weight
            });
            for (const service of sortedServices) {
                if (weightLeft < parseFloat(service.weight)) {
                    selectedService = service;
                    break;
                }
            }

            if (!selectedService) {
                this.props.showNoty('tr', 'error', 'Błąd podczas wczytywania danych usługi');
                this.setState(
                    {
                        calculatedZone: null,
                        calculatedZoneData: null,
                        calculatedPriceClient: clientPrice,
                        calculatedPriceFleet: fleetPrice,
                        calculatedPriceReal: realPrice,
                        calculatedPoints: points
                    }
                );
                return;
            }

            clientPrice += parseFloat(selectedService.basePriceClient);
            fleetPrice += parseFloat(selectedService.basePriceFleet);
            realPrice += parseFloat(selectedService.basePriceReal);

            this.setState({ service: selectedService.uuid })
        } else {
            this.setState({ service: '' })
        }

        this.setState({
            calculatedZone: selectedZone.name,
            calculatedZoneData: selectedZone,
            calculatedPriceClient: parseFloat(clientPrice).toFixed(2),
            calculatedPriceFleet: parseFloat(fleetPrice).toFixed(2),
            calculatedPriceReal: parseFloat(realPrice).toFixed(2),
            calculatedPoints: points
        });
    }

    handleAddElement = (element) => {
        this.setState({ elements: this.state.elements.concat([element]) }, () => { this.reCalculate() });
    };

    handleElementChange = (idx, field) => evt => {
        const newelements = this.state.elements.map((element, sidx) => {
            if (idx !== sidx) return element;
            let updatedelement = { ...element };
            updatedelement[field] = evt === null ? null : evt.target ? evt.target.value : evt.value;
            return updatedelement;
        });
        this.setState({ elements: newelements }, () => { this.reCalculate() });
    };

    handleRemoveElement = idx => () => {
        const newelements = this.state.elements.map((element, sidx) => {
            if (idx !== sidx) return element;
            return { ...element, remove: true };
        });
        this.setState({ elements: newelements }, () => { this.reCalculate() });
    };

    scrollToTop() {
        if (document) {
            if (document.documentElement) {
                document.documentElement.scrollTop = 0
            }
            if (document.scrollingElement) {
                document.scrollingElement.scrollTop = 0;
            }
        }
    }

    goBack() {
        if (this.state.currentStep !== 1) {
            this.setState({ currentStep: this.state.currentStep - 1 });
            this.scrollToTop();
        }
    }

    goForward() {

        let self = this;
        let local = {
            "elementsWeight": self.getElementsWeight()
        }

        let validationRules = {
            "isValid": true,
            "items": [
                { "step": [1], "local": "elementsWeight", "rules": { "empty": "Proszę wprowadzić numery oraz wagę zamówień" } },
                { "step": [2], "state": "streetAddress", "rules": { "empty": "Uzupełnij ulicę" } },
                { "step": [2], "state": "streetNumber", "rules": { "empty": "Uzupełnij numer budynku" } },
                { "step": [2], "state": "cityName", "rules": { "empty": "Uzupełnij miasto" } },
                { "step": [2], "state": "postalCode", "rules": { "empty": "Uzupełnij kod pocztowy" } },
                { "step": [2], "state": "dayIdent", "rules": { "empty": "Wybierz datę dostawy" } },
                { "step": [2], "state": "dayPart", "rules": { "empty": "Wybierz godzinę dostawy" } },
                { "step": [2], "state": "calculatedZone", "rules": { "empty": "Podany adres wykracza poza strefę dostaw" } },
                { "step": [3], "state": "firstName", "rules": { "empty": "Uzupełnij imię" } },
                { "step": [3], "state": "lastName", "rules": { "empty": "Uzupełnij nazwisko" } },
                { "step": [3], "state": "emailAddress", "type": "email", "rules": { "invalid": "Podaj poprawny adres e-mail", "empty": "Uzupełnij adres e-mail" } },
                { "step": [3], "state": "mobilePhone", "type": "phone", "rules": { "invalid": "Podaj poprawny numer telefonu", "empty": "Uzupełnij numer telefonu" } },
                { "step": [3], "state": "consent1", "rules": { "empty": "Zaakceptuj regulamin świadczenia usługi transportowej" } },
                { "step": [3], "state": "consent2", "rules": { "empty": "Zaakceptuj zgodę na przetwarzanie danych osobowych" } },
                { "step": [3], "state": "calculatedZone", "rules": { "empty": "Zweryfikuj adres dostawy (strefa nie została przypisana)" } },
                { "step": [3], "state": "calculatedPriceClient", "rules": { "empty": "Zweryfikuj dane (kwota nie została obliczona)" } },
            ]
        };

        function emailIsValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }

        function phoneIsValid(phoneNumber) {
            let found = phoneNumber.search(/^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/);
            return (found > -1) ? true : false;
        }

        validationRules.items.forEach(element => {
            if (!validationRules.isValid) {
                return;
            } else {
                let elementValue = null;
                if (element.local) {
                    elementValue = local[element.local];
                } else {
                    elementValue = self.state[element.state];
                }
                if (!_.includes(element.step, this.state.currentStep)) {
                    return;
                }
                if ("empty" in element.rules && (elementValue === "" || elementValue === null || elementValue === undefined || elementValue === 0 || elementValue === false)) {
                    validationRules.isValid = false;
                    self.props.showNoty('tr', 'error', element.rules.empty);
                }
                if ("type" in element) {
                    switch (element.type) {
                        case "email":
                            if (!emailIsValid(elementValue)) {
                                validationRules.isValid = false;
                                self.props.showNoty('tr', 'error', element.rules.invalid);
                            }
                            break;
                        case "phone":
                            if (!phoneIsValid(elementValue)) {
                                validationRules.isValid = false;
                                self.props.showNoty('tr', 'error', element.rules.invalid);
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        });

        if (!validationRules.isValid) {
            self.setState({ isLoading: false });
            return;
        }

        if (this.state.currentStep !== 3) {
            this.setState({ currentStep: this.state.currentStep + 1 });
            this.scrollToTop();
        } else {
            this.setState({ showSummaryModal: true });
        }

    }

    setDate(date) {
        if (moment(date).isBefore(moment())) {
            this.props.showNoty('tr', 'error', 'Najszybsza dostawa możliwa jest następnego dnia roboczego');
            return;
        }
        if (moment(date).isAfter(moment().add(30, 'd'))) {
            this.props.showNoty('tr', 'error', 'Proszę wybrać datę obejmującą najbliższe 30 dni');
            return;
        }
        if (moment(date).day() === 0) {
            this.props.showNoty('tr', 'error', 'Brak możliwości dostawy w niedzielę');
            return;
        }
        this.setState({ selectedCustomDate: moment(date).format("DD.MM.YYYY") })
    }

    setDayIdent(event) {
        this.setState({ dayIdent: event.target.value });
    }

    setDayPart(event) {
        this.setState({ dayPart: event.target.value });
    }

    setPayer(event) {
        this.setState({ payer: event.target.value });
    }

    setStore(storeData) {
        this.setState({ store: storeData }, () => { this.reFetchSlots(); });
    }

    saveOrder() {
        let self = this;

        //let selectedDate = self.state.dayIdent === "different" ? moment(self.state.selectedCustomDate).format("DD.MM.YYYY") : self.state.dayIdent;

        let commentWithInvoice = [
            `UWAGA - dane do faktury:`,
            `Nazwa firmy: ${self.state.invoiceCompanyName}`,
            `NIP: ${self.state.invoiceTaxNumber}`,
            `Ulica: ${self.state.invoiceStreetAddress}`,
            `Nr budynku: ${self.state.invoiceStreetNumber}`,
            `Nr lokalu: ${self.state.invoiceApartmentNumber}`,
            `Miasto: ${self.state.invoiceCityName}`,
            `Kod pocztowy: ${self.state.invoicePostalCode}`,
            `Miasto: ${self.state.invoiceCityName}`,
            `Preferowany termin dostawy: ${self.state.dayIdent} ${self.state.dayPart}`,
            `Komentarz: ${self.state.comment}`
        ].join("\n");

        let commentWithoutInvoice = [
            `Preferowany termin dostawy: ${self.state.dayIdent} ${self.state.dayPart}`,
            `Komentarz: ${self.state.comment}`
        ].join("\n");

        let comment = self.state.getInvoice ? commentWithInvoice : commentWithoutInvoice;
        let calculatedWeight = self.getElementsWeight();

        self.props.showNoty('tr', 'info', 'Zapisywanie zlecenia..');

        const serviceObject = self.state.service !== '' ? [{ "uuid": self.state.service, "quantity": 1 }] : [];

        _api.request(`/external/create-preorder`,
            {
                "type": self.state.store.type,
                "company": {
                    "uuid": self.state.store.value
                },
                "order": {
                    "payer": self.state.payer,
                    "comment": comment,
                    "calculatedPriceClient": self.state.calculatedPriceClient,
                    "calculatedPriceFleet": self.state.calculatedPriceFleet,
                    "calculatedPriceReal": self.state.calculatedPriceReal,
                    "calculatedPoints": self.state.calculatedPoints,
                    "calculatedWeight": calculatedWeight,
                },
                "address": {
                    "name": [self.state.firstName, self.state.lastName].join(" "),
                    "streetAddress": self.state.streetAddress,
                    "streetNumber": self.state.streetNumber,
                    "cityName": self.state.cityName,
                    "postalCode": self.state.postalCode,
                    "apartmentNumber": self.state.apartmentNumber,
                    "apartmentFloor": self.state.apartmentFloor,
                    "elevator": self.state.elevator,
                    "emailAddress": self.state.emailAddress,
                    "mobilePhone": self.state.mobilePhone
                },
                "service": serviceObject,
                "element": self.state.elements.filter(el => el.remove === false),
                "zone": {
                    "uuid": (self.state.calculatedZoneData) ? self.state.calculatedZoneData.uuid : null
                }
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Zlecenie zostało zapisane');
                self.setState({ showSummaryModal: false, showSaved: true });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisu zlecenia');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        const elementsLength = this.state.elements.filter(el => el.remove === false).length;

        const elementsWeight = this.getElementsWeight();

        const canGoBack = this.state.currentStep !== 1;

        const canGoForward = elementsLength ? true : false;

        const transportCostClient = this.state.calculatedPriceClient ? `${this.getCalculatedPriceClient()}zł` : "brak danych";

        const infoCostMap = new Map([
            [
                TypeOfService.get('BASIC'),
                <Card
                    title={`Koszt transportu: ${transportCostClient}`}
                    category={<>W cenie: transport, wniesienie do pierwszego zadaszonego pomieszczenia</>}
                />
            ],
            [
                TypeOfService.get('STANDARD'),
                <Card
                    title={`Koszt transportu: ${transportCostClient}`}
                    category={<>W cenie: transport, wniesienie do pomieszczenia wskazanego przez Klienta, okazanie stanu opakowań</>}
                />
            ],
            [
                TypeOfService.get('PREMIUM'),
                <Card
                    title={`Koszt transportu: ${transportCostClient}`}
                    category={<>W cenie: wniesienie, rozpakowanie, ustawienie, usunięcie opakowań, drobny montaż <i className="fa fa-info-circle" style={{ cursor: "pointer" }} onClick={() => { this.setState({ showInfoModal: true }) }} /></>}
                />
            ]
        ]);

        const transportCost = this.state.typeOfService ? infoCostMap.get(this.state.typeOfService) : infoCostMap.get(TypeOfService.get('BASIC'));

        const infoModal = (
            <Modal show={this.state.showInfoModal} onHide={() => { this.setState({ showInfoModal: false }) }} style={{ paddingLeft: 0, paddingRight: 0 }} backdrop="static">
                <Modal.Header closeButton>Informacja</Modal.Header>
                <Modal.Body>
                    <strong>Drobny montaż w postaci:</strong> przykręcenia okuć do mebla, przykręcenia boczków wersalek, narożników i foteli
                    (z wyłączeniem modelu typu finka), montażu podnóżków, nóżek, stopek, listew ozdobnych do kanap i narożników, montażu kanap typu DL,
                    montażu wezgłowia do łóżek typu RELAX, montażu zaczepów do narożników i łączenia elementów narożnika w całość,
                    w tym narożników uniwersalnych, montażu uchwytów, półek do mebli skrzyniowych, montażu wieńców i koron do mebli skrzyniowych,
                    z wyłączeniem poziomowania mebli u klienta. Drobny montaż dotyczy brył w całości i mebli tapicerowanych,
                    którego łączny czas wykonania trwa nie dłużej niż 15 minut, na każdy mebel podlegający drobnemu montażowi.
                </Modal.Body>
            </Modal >
        )

        const termsModal = (
            <Modal show={this.state.showTermsModal} onHide={() => { this.setState({ showTermsModal: false }) }} style={{ paddingLeft: 0, paddingRight: 0 }} backdrop="static">
                <Modal.Header closeButton>Regulamin</Modal.Header>
                <Modal.Body>
                    Treść regulaminu
                </Modal.Body>
            </Modal >
        )

        const summaryModal = (
            <Modal show={this.state.showSummaryModal} onHide={() => { this.setState({ showSummaryModal: false }) }} bsSize="large" style={{ paddingLeft: 0, paddingRight: 0 }} backdrop="static">
                <Modal.Body>
                    <h3>Podsumowanie zamówienia</h3>
                    <p className="lead" style={{ fontSize: "14px" }}>Prosimy o sprawdzenie w podsumowaniu poprawności uzupełnionych danych. Na podany nymer telefony oraz adres mailowy zostanie wysłane potwierdzenie złożenia zamówienia, a także przyszłe powiadomienia o statusie zlecenia.</p>

                    <Row className="push-30">
                        <Col md={4}>
                            <strong>Dostawa z:</strong>
                            <p className="light">{this.state.store.label}</p>
                        </Col>
                        <Col md={4}>
                            <strong>Dostawa do:</strong>
                            <p className="light">{[this.state.streetAddress, this.state.streetNumber, this.state.apartmentFloor, this.state.postalCode, this.state.cityName].join(", ")}</p>
                        </Col>
                        <Col md={4}>
                            <strong>Dane odbiorcy:</strong>
                            <p className="light">{[this.state.firstName, this.state.lastName, this.state.emailAddress, this.state.mobilePhone].join(", ")}</p>
                        </Col>
                    </Row>

                    <Row className="push-30">
                        <Col md={4}>
                            <strong>Ilość zamówień:</strong>
                            <p className="light">{elementsLength}</p>
                        </Col>
                        <Col md={4}>
                            <strong>Preferowany termin dostawy:</strong>
                            <p className="light">{moment().format('YYYY-MM-DD')}</p>
                        </Col>
                        <Col md={4}>
                            <strong>Faktura:</strong>
                            <p className="light">{this.state.getInvoice ? [this.state.invoiceCompanyName, this.state.invoiceTaxNumber, this.state.invoicePostalCode, this.state.invoiceCityName, this.state.invoiceStreetNumber, this.state.invoiceApartmentNumber].join(" ") : "Nie"}</p>
                        </Col>
                    </Row>

                    <Row className="push-30">
                        <Col md={4}>
                            <strong>Waga zamówień:</strong>
                            <p className="light">{elementsWeight.toFixed(2)}</p>
                        </Col>
                        <Col md={4}>
                            <strong>Preferowane godziny dostawy:</strong>
                            <p className="light">13:00 - 17:00</p>
                        </Col>
                        <Col md={4}>
                            <strong>Wybrana płatność:</strong>
                            <p className="light">{this.state.payer === "EXPERT" ? `Transport opłacony przelewem na konto Expert` : `Transport został opłacony w salonie Agata`}</p>
                        </Col>
                    </Row>

                    <Row className="push-30">
                        <Col md={12}>
                            <strong>Komentarz:</strong>
                            <p className="light">{this.state.comment}</p>
                        </Col>
                    </Row>

                    {transportCost}

                    <Row style={{ marginTop: "30px" }}>
                        <Col xs={6}>
                            <Button bsStyle="default" fill wd icon onClick={() => { this.setState({ showSummaryModal: false }) }}>
                                <>Wróć do edycji danych</>
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button bsStyle="success" fill wd icon pullRight onClick={() => { this.saveOrder() }}>
                                <>Zamawiam transport</>
                            </Button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal >
        )

        /*
        const datePicker = (
            <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "Inny termin" }}
                min={moment()}
                value={this.state.selectedCustomDate}
                defaultValue={this.state.selectedCustomDate}
                onChange={value =>
                    this.setDate(value)
                }
            />
        );
        */

        const step1 =
            (
                <div>

                    <FormGroup>
                        <ControlLabel>Wybierz Salon Agata, w którym meble zostały zakupione:</ControlLabel>
                        <Col style={{ marginTop: "15px" }}>
                            <Row>
                                {this.storeOptions.map((storeData, index) => {
                                    return (
                                        <div key={storeData.value}>
                                            <Col md={6}>
                                                <Panel>
                                                    <Panel.Body>
                                                        <Radio
                                                            number={index}
                                                            name="store"
                                                            option={storeData.value}
                                                            value={storeData.value}
                                                            label={storeData.label.split('\n').map((item, key) => {
                                                                return <span key={key}>{item}<br /></span>
                                                            })}
                                                            labelStyle={{ fontSize: "14px" }}
                                                            checked={this.state.store.value === storeData.value}
                                                            onChange={() => { this.setStore(storeData) }}
                                                            style={{ border: '1px solid #fff' }}
                                                        />
                                                    </Panel.Body>
                                                </Panel>
                                            </Col>
                                            {(index + 1) % 2 === 0 ? (
                                                <Clearfix />
                                            )
                                                :
                                                (<></>)
                                            }
                                        </div>
                                    )
                                })}
                            </Row>

                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Podaj numery zamówień oraz wagę:</ControlLabel>
                        <p className="light">Informacje te znajdziesz na wydruku ze szczegółami zamówienia. Znajdź numer zamówienia oraz wagę i wpisz w odpowiednie pola. Pomoże to dobrze zorganizować dostawę pod wskazany adres.</p>
                    </FormGroup>

                    <Table responsive style={{ marginBottom: 0 }}>
                        <tbody>
                            {!elementsLength ? <tr><td colSpan="3" className="text-muted" style={{ padding: 0 }}>Brak zamówień.</td></tr> :
                                this.state.elements.map((element, idx) => {
                                    if (element.remove) {
                                        return null;
                                    }
                                    return (
                                        <tr key={idx}>
                                            <td style={{ paddingLeft: 0, width: "60%" }}>
                                                <FormControl
                                                    type="text"
                                                    placeholder={`Numer zamówienia`}
                                                    value={element.name || ""}
                                                    onChange={this.handleElementChange(idx, 'name')}
                                                />
                                            </td>
                                            <td style={{ padding: 0 }}>
                                                <FormControl
                                                    type="number"
                                                    step="any"
                                                    lang="en"
                                                    placeholder={`Waga (kg)`}
                                                    value={element.weight || ""}
                                                    onChange={this.handleElementChange(idx, 'weight')}
                                                />
                                            </td>
                                            <td style={{ paddingRight: 0 }}>
                                                <Button
                                                    onClick={this.handleRemoveElement(idx)}
                                                    bsStyle="default"
                                                    simple
                                                    icon>
                                                    <i className="fa fa-times" />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                        </tbody>
                    </Table>

                    <Button simple bsStyle="default" onClick={() => { this.handleAddElement(this.elementPrototype) }} disabled={this.state.isPreviewOnly} style={{ paddingLeft: 0, marginTop: "10px" }}>
                        <span className="btn-label" style={{ paddingRight: "5px" }}>
                            <i className="fa fa-plus-circle" />
                        </span>
                        <>dodaj kolejne zamówienie</>
                    </Button>

                </div>
            );

        const step2 = (
            <div>
                <FormGroup>
                    <ControlLabel>Wpisz adres dostawy:</ControlLabel>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={12} md={5}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Ulica`}
                                value={this.state.streetAddress || ""}
                                onChange={(e) => { this.setState({ streetAddress: e.target.value }) }}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Nr budynku`}
                                value={this.state.streetNumber || ""}
                                onChange={(e) => { this.setState({ streetNumber: e.target.value }) }}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Nr lokalu`}
                                value={this.state.apartmentNumber || ""}
                                onChange={(e) => { this.setState({ apartmentNumber: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={12} md={5}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Miasto`}
                                value={this.state.cityName || ""}
                                onChange={(e) => { this.setState({ cityName: e.target.value }) }}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <DebounceInput
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                                minLength={2}
                                debounceTimeout={2500}
                                type="text"
                                value={this.state.postalCode}
                                placeholder={`Kod pocztowy`}
                                onChange={(e) => { this.setState({ postalCode: e.target.value }, () => { this.reCalculate() }) }}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Piętro`}
                                value={this.state.apartmentFloor || ""}
                                onChange={(e) => { this.setState({ apartmentFloor: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Wybierz preferowany termin dostawy:</ControlLabel>
                    <p className="light">Uwaga: wybrany termin oznacza preferowany dzień dostawy, który może ulec zmianie w toku planowania.
                        W przypadku zaistnienia takiej sytuacji pracownik firmy transportowej skontaktuje się telefonicznie w celu ustalenia ostatecznego terminu realizacji.</p>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col md={6}>
                            <Radio
                                number={1}
                                name="dayIdent"
                                option={this.state.days[0].value}
                                label={this.state.days[0].name}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.dayIdent === this.state.days[0].value}
                                onChange={this.setDayIdent.bind(this)}
                            />
                        </Col>
                        <Col md={6}>
                            <Radio
                                number={2}
                                name="dayIdent"
                                option={this.state.days[1].value}
                                label={this.state.days[1].name}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.dayIdent === this.state.days[1].value}
                                onChange={this.setDayIdent.bind(this)}
                            />
                        </Col>
                        <Col md={6}>
                            <Radio
                                number={3}
                                name="dayIdent"
                                option={this.state.days[2].value}
                                label={this.state.days[2].name}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.dayIdent === this.state.days[2].value}
                                onChange={this.setDayIdent.bind(this)}
                            />
                        </Col>
                        <Col md={6}>
                            <Radio
                                number={4}
                                name="dayIdent"
                                option="inna"
                                label={`Inna`}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.dayIdent === "inna"}
                                onChange={this.setDayIdent.bind(this)}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Wybierz preferowany przedział godzinowy dostawy:</ControlLabel>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col md={6} lg={4}>
                            <Radio
                                number={10}
                                name="dayPart"
                                option={"10:00 - 14:00"}
                                label={"10:00 - 14:00"}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.dayPart === "10:00 - 14:00"}
                                onChange={this.setDayPart.bind(this)}
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <Radio
                                number={11}
                                name="dayPart"
                                option={"13:00 - 17:00"}
                                label={"13:00 - 17:00"}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.dayPart === "13:00 - 17:00"}
                                onChange={this.setDayPart.bind(this)}
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <Radio
                                number={12}
                                name="dayPart"
                                option={"16:00 - 20:00"}
                                label={"16:00 - 20:00"}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.dayPart === "16:00 - 20:00"}
                                onChange={this.setDayPart.bind(this)}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Wybierz rodzaj usługi:</ControlLabel>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col md={6} lg={4}>
                            <Radio
                                number={13}
                                name="typeOfService"
                                option={TypeOfService.get('BASIC')}
                                label={TypeOfService.get('BASIC')}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.typeOfService === TypeOfService.get('BASIC')}
                                onChange={(e) => {
                                        this.setState(
                                            { typeOfService: e.target.value },
                                        () => { this.reCalculate() }
                                        )
                                    }
                                }
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <Radio
                                number={15}
                                name="typeOfService"
                                option={TypeOfService.get('STANDARD')}
                                label={TypeOfService.get('STANDARD')}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.typeOfService === TypeOfService.get('STANDARD')}
                                onChange={(e) => {
                                    this.setState(
                                        { typeOfService: e.target.value },
                                        () => { this.reCalculate() }
                                    )
                                }
                                }
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <Radio
                                number={14}
                                name="typeOfService"
                                option={TypeOfService.get('PREMIUM')}
                                label={TypeOfService.get('PREMIUM')}
                                labelStyle={{ fontSize: "14px" }}
                                checked={this.state.typeOfService === TypeOfService.get('PREMIUM')}
                                onChange={(e) => {
                                    this.setState(
                                        { typeOfService: e.target.value },
                                        () => { this.reCalculate() }
                                    )
                                }
                                }
                            />
                        </Col>
                    </Row>
                </FormGroup>

                {transportCost}

            </div>
        );

        const step3 = (
            <div>
                <FormGroup>
                    <ControlLabel>Dane odbiorcy (np. Twoje):</ControlLabel>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Imię`}
                                value={this.state.firstName || ""}
                                onChange={(e) => { this.setState({ firstName: e.target.value }) }}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Nazwisko`}
                                value={this.state.lastName || ""}
                                onChange={(e) => { this.setState({ lastName: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="email"
                                placeholder={`E-mail`}
                                value={this.state.emailAddress || ""}
                                onChange={(e) => { this.setState({ emailAddress: e.target.value }) }}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="number"
                                placeholder={`Telefon kontaktowy`}
                                value={this.state.mobilePhone || ""}
                                onChange={(e) => { this.setState({ mobilePhone: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Checkbox
                        number={20}
                        label={<>Chcę uzupełnić dane do faktury</>}
                        checked={this.state.getInvoice}
                        onChange={event => {
                            this.setState({ getInvoice: event.target.checked });
                        }}
                    />
                </FormGroup>

                {this.state.getInvoice && (
                    <>

                        <FormGroup>
                            <Row>
                                <Col sm={12} md={6}>
                                    <FormControl
                                        style={{ marginBottom: "5px" }}
                                        type="text"
                                        placeholder={`Nazwa firmy`}
                                        value={this.state.invoiceCompanyName || ""}
                                        onChange={(e) => { this.setState({ invoiceCompanyName: e.target.value }) }}
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <FormControl
                                        style={{ marginBottom: "5px" }}
                                        type="text"
                                        placeholder={`NIP`}
                                        value={this.state.invoiceTaxNumber || ""}
                                        onChange={(e) => { this.setState({ invoiceTaxNumber: e.target.value }) }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col sm={12} md={5}>
                                    <FormControl
                                        style={{ marginBottom: "5px" }}
                                        type="text"
                                        placeholder={`Ulica`}
                                        value={this.state.invoiceStreetAddress || ""}
                                        onChange={(e) => { this.setState({ invoiceStreetAddress: e.target.value }) }}
                                    />
                                </Col>
                                <Col sm={12} md={4}>
                                    <FormControl
                                        style={{ marginBottom: "5px" }}
                                        type="text"
                                        placeholder={`Nr budynku`}
                                        value={this.state.invoiceStreetNumber || ""}
                                        onChange={(e) => { this.setState({ invoiceStreetNumber: e.target.value }) }}
                                    />
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormControl
                                        style={{ marginBottom: "5px" }}
                                        type="text"
                                        placeholder={`Nr lokalu`}
                                        value={this.state.invoiceApartmentNumber || ""}
                                        onChange={(e) => { this.setState({ invoiceApartmentNumber: e.target.value }) }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col sm={12} md={6}>
                                    <FormControl
                                        style={{ marginBottom: "5px" }}
                                        type="text"
                                        placeholder={`Miasto`}
                                        value={this.state.invoiceCityName || ""}
                                        onChange={(e) => { this.setState({ invoiceCityName: e.target.value }) }}
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <FormControl
                                        style={{ marginBottom: "5px" }}
                                        type="text"
                                        placeholder={`Kod pocztowy`}
                                        value={this.state.invoicePostalCode || ""}
                                        onChange={(e) => { this.setState({ invoicePostalCode: e.target.value }) }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                    </>
                )}

                <FormGroup>
                    <ControlLabel>Uwagi do zamówienia:</ControlLabel>
                    <textarea
                        className="form-control"
                        value={this.state.comment}
                        onChange={event =>
                            this.setState({ comment: event.target.value })
                        }
                    />
                </FormGroup>

                <FormGroup onChange={this.setPayer.bind(this)}>
                    <ControlLabel>Wybrana płatność:</ControlLabel>
                    <Row>
                        <Col md={6}>
                            <Radio
                                number={10}
                                name="payer"
                                option={"Salon AM"}
                                label={<>Transport został opłacony w&nbsp;salonie&nbsp;Agata</>}
                                labelStyle={{ fontSize: "14px" }}
                            />
                        </Col>
                        <Col md={6}>
                            <Radio
                                number={11}
                                name="payer"
                                option={"EXPERT"}
                                label={<>Transport opłacony przelewem na&nbsp;konto&nbsp;Expert</>}
                                labelStyle={{ fontSize: "14px" }}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                {transportCost}

                <FormGroup>
                    <p style={{ fontSize: "13px" }}><Button bsStyle="default" onClick={() => { this.setState({ showTermsModalDISABLED: true }) }}>Regulamin</Button></p>
                    <Checkbox
                        number={30}
                        label={<>Akceptuję regulamin świadczenia usługi transportowej</>}
                        checked={this.state.consent1}
                        onChange={event => {
                            this.setState({ consent1: event.target.checked });
                        }}
                    />
                    <Checkbox
                        number={31}
                        label={"Wyrażam zgodę na przetwarzanie moich danych osobowych przez Expert Sp. z o. o. w celu realizacji zlecenia i przeprowadzenia ankiety badania satysfakcji z naszych usług za pośrednictwem telefonu zgodnie z art. 172 ustawą z dnia 16 lipca 2004 r. - Prawo telekomunikacyjne (t.j. Dz. U. z 2017 r. poz. 1907 z późn.zm.)"}
                        checked={this.state.consent2}
                        onChange={event => {
                            this.setState({ consent2: event.target.checked });
                        }}
                    />
                </FormGroup>

            </div>
        );

        const currentStepForm = this.state.currentStep === 1 ? step1 : this.state.currentStep === 2 ? step2 : step3;


        const orderPage = (
            <>
                <h4 className="text-center" style={{ marginBottom: "30px" }}>Zamówienie usługi transportowej</h4>

                <FormGroup className="push-30 line-accross">
                    <Button
                        style={{ width: "50px", height: "50px" }}
                        className={["btn-round btn-icon btn btn-default", this.state.currentStep === 1 ? " step-active" : ""].join("")}>
                        <>1</>
                    </Button>

                    <Button
                        style={{ width: "50px", height: "50px", left: "calc(50% - (25px + 50px)" }}
                        className={["btn-round btn-icon btn btn-default", this.state.currentStep === 2 ? " step-active" : ""].join("")}>
                        <>2</>
                    </Button>

                    <Button
                        style={{ width: "50px", height: "50px", float: "right" }}
                        className={["btn-round btn-icon btn btn-default", this.state.currentStep === 3 ? " step-active" : ""].join("")}>
                        <>3</>
                    </Button>
                </FormGroup>

                <form onSubmit={(event) => { event.preventDefault(); }}>
                    {currentStepForm}
                </form>

                <Row style={{ marginTop: "30px" }}>
                    <Col xs={6}>
                        <Button disabled={!canGoBack} bsStyle="default" fill wd icon onClick={!this.state.isLoading ? this.goBack.bind(this) : null}>
                            <>Wstecz</>
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button disabled={!canGoForward} bsStyle="default" fill wd pullRight icon onClick={!this.state.isLoading ? this.goForward.bind(this) : null}>
                            <>Dalej</>
                        </Button>
                    </Col>
                </Row>
            </>
        );

        const thankYouPage = (
            <div className="push-30">
                <h2 className="text-center" style={{ marginTop: "30vh", marginBottom: "30px" }}>Dziękujemy!</h2>
                <p className="lead" style={{ padding: "20px" }}>Twoje zlecenie zostało przyjęte. Nasi pracownicy skontaktują się z&nbsp;Tobą w&nbsp;celu ustalenia dalszych szczegółów.</p>
                <p className="text-right lead" style={{ padding: "20px" }}><i>Zespół SUUS Expert</i></p>
            </div>
        );

        return (
            <div className="main-content">
                {infoModal}
                {termsModal}
                {summaryModal}
                <Grid fluid>
                    <Row>
                        <Col smHidden md={4} style={{ padding: 0, backgroundImage: "url(" + bgImage + ")", backgroundPosition: "center center", backgroundSize: "cover", height: "100%", minHeight: "100vh", position: "fixed" }}>
                        </Col>
                        <Col xs={12} md={8} mdPush={4} style={{ paddingBottom: "60px" }}>
                            <Row>
                                <Col mdPush={1} md={10}>
                                    {this.state.showSaved ? thankYouPage : orderPage}
                                </Col>
                            </Row>
                            <Row style={{ borderTop: "1px solid #ddd", paddingTop: "20px", marginTop: "60px" }}>
                                <Col mdPush={2} md={8}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><a href="https://expert.suus.com/"><img src={logo} alt="Expert - Zlecenia" style={{ maxWidth: "100px" }} /></a></td>
                                                <td style={{ paddingLeft: "20px" }}>
                                                    <p className="light">
                                                        Za transport Twoich mebli odpowiada firma SUUS EXPERT. W&nbsp;razie pytań zadzwoń: <a href="tel:+48585008489">+48 58 500 84 89</a> lub napisz <a href={`mailto:${this.state.store.pokEmailAddress}`}>{`${this.state.store.pokEmailAddress}`}</a>.
                                                        W celu przyjęcia i kompleksowej realizacji zleconej usługi przetwarzamy Twoje dane osobowe, szczegóły na ten temat znajdziesz tutaj <a href="https://expert.suus.com/RODO" target="_blank">https://expert.suus.com/RODO</a>.
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default ExpertForm;
