import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { style } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import LoadingBar from 'react-top-loading-bar';
import routes from "../routes.js";

class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _notificationSystem: null,
            loadingBarProgress: 0,
        };
    }

    componentDidMount() {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
    }

    showNoty = (position, level, content) => {
        this.state._notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-info" />,
            message: (<div>{content}</div>),
            level: level,
            position: position,
            autoDismiss: 5
        });
    };

    setProgress = (value) => {
        this.setState({ loadingBarProgress: value });
    };

    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            return (
                <Route
                    path={prop.layout + prop.path}
                    key={key}
                    render={routeProps => (
                        <prop.component
                            {...routeProps}
                            setProgress={this.setProgress}
                            showNoty={this.showNoty}
                        />
                    )}
                />
            );
        });
    };

    render() {
        let loadingBar = (
            <div>
                <LoadingBar
                    progress={this.state.loadingBarProgress}
                    height={3}
                    color='#df9125'
                />
            </div>
        );
        return (
            <div>
                {loadingBar}
                <NotificationSystem ref="notificationSystem" style={style} />
                <div className="wrapper wrapper-full-page wrapper-full-page-order">
                    <div>
                        <div className="content">
                            <Switch>{this.getRoutes(routes)}</Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Order;
