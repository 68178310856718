import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard-pro-react.scss";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import OrderLayout from "./layouts/Order.jsx";

ReactDOM.render(
    <HashRouter>
        <Switch>
            <Route path="/" render={props => <OrderLayout {...props} />} />
        </Switch>
    </HashRouter>,
    document.getElementById("root")
);
